import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import React from "react";
import footerimg from "../../assets/images/footer.png";
import back from "../../assets/images/logo_without_bg.png";

const styles = StyleSheet.create({
  pageFooter: {
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerImage: {
    position: "absolute",
    width: "100%",
    height: "50px",
    opacity: 0.5,
  },
  contentContainer: {
    zIndex: 1, // Ensure content is above the footer image
    marginTop: 5,
  },
  cells: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  copyright: {
    textAlign: "right",
    color: "black",
    paddingRight: "5px",
    fontSize: "10px",
  },
  ereport: {
    color: "black",
    fontSize: "10px",
    textDecoration: "underline",
  },
  url: {
    textAlign: "left",
  },
  warnMsg: {
    fontSize: "10px",
    textAlign: "center",
    paddingLeft: "120px",
  },
  image: {
    width: "auto",
    height: "30px",
  },
  new: {
    flexDirection: "row",
    alignItems: "center",
  },
  dateTime: {
    textAlign: "right",
    color: "black",
    paddingRight: "5px",
    fontSize: "10px",
  },
});

const PrescriptionFooter = () => {
  const currentDateTime = new Date().toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return (
    <View style={styles.pageFooter} fixed>
      <Image style={styles.footerImage} src={footerimg} />
      <View style={styles.contentContainer}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text></Text>
          <Text style={styles.warnMsg}>(Not Valid for Medico Legal Cases)</Text>
          <Text style={styles.dateTime}>{currentDateTime}</Text>
        </View>
        <View style={styles.cells}>
          <View style={styles.new}>
            <Image src={back} style={styles.image} />
            <Text style={styles.url}>www.Docmanageplus.com</Text>
          </View>
          <Text style={styles.ereport}>E Report</Text>

          <Text style={styles.copyright}>
            &#169; {new Date().getFullYear()}, All rights reserved.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PrescriptionFooter;
