import { Box, Button } from "@mui/material";
import React from "react";
import heroImg from "../../assets/images/heroimg.jpg";
import PersonAddTwoToneIcon from "@mui/icons-material/PersonAddTwoTone";
import { Link } from "react-router-dom";

const BgImageSection = () => {
  return (
    <Box sx={{ position: "relative", height: "367px" }}>
      <Box
        sx={{
          background: `url(${heroImg}) center / cover no-repeat`,
          height: "100%",
          "@media (max-width:767px)": {
            background: "#cde4f3",
          },
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "0 20px",
          lineHeight: 1.5,
          "@media (max-width:767px)": {
            // Responsive adjustment for smaller screens
            width: "100%",
            padding: "0 10px",
            textAlign: "center",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            fontSize: "30px",
            fontWeight: 600,
            color: "#023275",
            paddingBottom: "7px",
            width: "80%",
            "@media (max-width:1200px)": {
              fontSize: "20px !important",
              paddingBottom: "3px",
            },
            "@media (max-width:767px)": {
              fontSize: "22px !important",
            },
            "@media (max-width:355px)": {
              fontSize: "20px !important",
            },
          }}
        >
          "Docmanageplus: Uniting Expertise with Empathy on Your Path to Health and
          Wellness"
        </Box>
        <Box
          sx={{
            width: "70%",
            fontSize: "15px",
            color: "black",
            "@media (max-width:767px)": {
              // Adjust font size for smaller screens
              fontSize: "13px !important",
            },
            "@media (max-width:355px)": {
              // Adjust font size for smaller screens
              width: "90%",
            },
          }}
        >
          Communication is at the core of our approach to care. At Docmanageplus, we
          are dedicated to fostering clear and empathetic conversations,
          ensuring your path to wellness is navigated with understanding,
          comprehensive support, and tailored to your unique needs.
        </Box>

        <Box
          sx={{
            position: "relative",
            top: "0.75rem",
            "@media (max-width:1200px)": {
              top: "0.5rem",
            },
          }}
        >
          <Link to="/create-account">
            <Button
              sx={{
                textTransform: "none",
                color: "white",
                border: "2px solid #023275",
                borderRadius: "999px",
                padding: "5px 30px",
                fontWeight: "600",
                background: "#023275",
                fontSize: "16px",
                "@media (max-width:767px)": {
                  padding: "2px 10px",
                },
                "&:hover": {
                  background: "#1b509b",
                  border: "2px solid #1b509b",
                  color: "white",
                },
              }}
              endIcon={<PersonAddTwoToneIcon />}
            >
              Join us
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default BgImageSection;
