import React, { useContext, useEffect } from "react";
import Navbar from "../../Layout/Navbar";
import "./Notes.css";
import CardForNote from "./CardForNote";
import InputBoxForNote from "./InputBoxForNote";
import AuthContext from "../../context/AuthContext";
import { Helmet } from "react-helmet";
const Notes = () => {
  const { allNotesData, fetchNotesData, isNoteUpdate } =
    useContext(AuthContext);

  useEffect(() => {
    fetchNotesData();
  }, [isNoteUpdate]);

  return (
    <>
      <Helmet>
        <title>Docmanageplus | Notes</title>
      </Helmet>
      <Navbar />
      <section className="notes-page">
        <div className="container py-3">
          <InputBoxForNote />
          <CardForNote data={allNotesData} />
        </div>
      </section>
    </>
  );
};

export default Notes;
