import React, { useContext, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  BlobProvider,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useParams } from "react-router";
import AuthContext from "../../context/AuthContext";
import Loading from "../../Layout/Loading";
import PersonalDetails from "./PersonalDetails";
import AnalysisDetails from "./AnalysisDetails";
import MedicinePrescription from "./MedicinePrescription";
import PrescriptionHeader from "./PrescriptionHeader";
import PrescriptionFooter from "./PrescriptionFooter";
import pageBg from "../../assets/images/logo_without_bg.png";
import { Helmet } from "react-helmet";

// Registering fonts
Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

const PrescriptionPdfRender = () => {
  const { patientId, visitId } = useParams();
  const {
    fetchPatientDataUsingPatientIdAndVisitId,
    pdfDataUsingPatientAndVisitData,
    userInfo,
    fetchUserInfo,
  } = useContext(AuthContext);

  useEffect(() => {
    if (!userInfo) {
      fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    fetchPatientDataUsingPatientIdAndVisitId(patientId, visitId);
  }, [patientId, visitId]);

  const document = (
    <Document>
      <Page style={styles.page} size="A4">
        <PrescriptionHeader
          name={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
          clinicAddress={userInfo?.clinic_address || ""}
          contactTimings={userInfo?.contact_timings || ""}
          contactNumber={userInfo?.official_contact_number || ""}
          qualification={userInfo?.qualification || ""}
          registrationNumber={userInfo?.registration_number || ""}
        />
        <PersonalDetails
          personalDetails={pdfDataUsingPatientAndVisitData?.personalData}
          visitDate={pdfDataUsingPatientAndVisitData?.visitDate}
        />
        <View style={styles.content}>
          <Image src={pageBg} style={styles.pageBgImg} />
          <View style={styles.pageView}>
            <AnalysisDetails
              analysisDetails={pdfDataUsingPatientAndVisitData?.analysisData}
            />
            <View style={styles.rx}>
              <Text style={styles.rxSymbol}>
                R<Text style={styles.subscript}>x</Text>
              </Text>
            </View>
            <MedicinePrescription
              medicineData={pdfDataUsingPatientAndVisitData?.medicineData}
            />
          </View>
        </View>
        <PrescriptionFooter />
      </Page>
    </Document>
  );

  return (
    <>
      <Helmet>
        <title>Docmanageplus | Patient's Prescription</title>
      </Helmet>
      {pdfDataUsingPatientAndVisitData ? (
        <BlobProvider document={document}>
          {({ blob, url, loading, error }) => {
            if (loading) return <Loading />;
            if (error) return <div>Error generating PDF</div>;
            return (
              <iframe
                src={url}
                style={styles.iframeStyle}
                title="prescription-pdf"
              />
            );
          }}
        </BlobProvider>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default PrescriptionPdfRender;

const styles = StyleSheet.create({
  pageBgImg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 0.1,
  },
  page: {
    flexDirection: "column",
    color: "black",
    position: "relative",
  },
  content: {
    height: "73vh",
    marginTop: 10,
  },
  pageView: {
    padding: "0px 25px",
  },
  rx: {
    padding: "0 10px",
    fontFamily: "Lato Bold",
    color: "#03045e",
  },
  rxSymbol: {
    fontFamily: "Lato Bold",
    color: "#03045e",
    fontSize: "25pt",
  },
  subscript: {
    fontSize: "15pt",
  },
  iframeStyle: {
    width: "100%",
    minHeight: "100%",
    border: "none",
    overflow: "hidden",
  },
});
