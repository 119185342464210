import React, { useState } from "react";
import Box from "@mui/material/Box";
import "./SupportPanel.css";
import Button from "@mui/material/Button";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SupportTicketsTable from "./SupportTicketsTable";
import CreateTicketModal from "./CreateTicketModal";
import { saveButtonStyle } from "../../styledComponents/CustomStyleComponents";
import { Helmet } from "react-helmet";

const SupportPanel = () => {
  const [openCreateTicketModal, setOpenCreateTicketModal] = useState(false);
  const handleOpenTicketModal = () => setOpenCreateTicketModal(true);

  return (
    <>
      <Helmet>
        <title>Docmanageplus | Support Panel</title>
      </Helmet>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        bgcolor="#121212"
        color="#e1e1e1"
      >
        <div className="supportpanel-header">Support Panel</div>
        <Box m={1} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            endIcon={<ControlPointOutlinedIcon />}
            onClick={handleOpenTicketModal}
            sx={{ ...saveButtonStyle, ml: 1 }}
          >
            Create
          </Button>
        </Box>
        <SupportTicketsTable />
        <CreateTicketModal
          open={openCreateTicketModal}
          setOpen={setOpenCreateTicketModal}
        />
      </Box>
    </>
  );
};

export default SupportPanel;
