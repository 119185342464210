import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import logo from "../../assets/images/logo_without_bg.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const drawerWidth = 240;
const navItems = ["Home", "Contact", "About Us", "What we do?"];

const HomeAppBar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const demoBtn = (
    <Link to="#">
      <Button
        sx={{
          textTransform: "none",
          color: "black",
          border: "2px solid #0C356A",
          borderRadius: "19px",
          padding: "2px 20px",
          fontWeight: "600",
          "@media (max-width:600px)": {
            padding: "2px 10px",
          },
          "& .MuiButton-startIcon": {
            marginRight: 0, // Set right margin to 0
          },
        }}
        startIcon={<ArrowBackIosIcon style={{ color: "#0C356A" }} />}
      >
        Demo
      </Button>
    </Link>
  );

  const desktopViewSlogan = (
    <Box
      sx={{
        display: { xs: "none", sm: "flex" },
        height: "61px",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1% 2%",
        bgcolor: "#0C356A",
        "@media (max-width:800px)": {
          height: "55px",
        },
      }}
    >
      <Box>
        <img src={logo} alt="Docmanageplus" height={40} width="auto" />
      </Box>
      <Box
        sx={{
          fontSize: "18px",
          color: "white",
          "@media (max-width:800px)": {
            fontSize: "13px",
            marginLeft: "-1.25rem",
          },
        }}
      >
        Fusing Innovation with Empathy for Excellence in Patient Care
      </Box>
      <Box display="flex" flexDirection="row">
        <Link to="/login">
          <Button
            sx={{ textTransform: "none", color: "white" }}
            startIcon={<PeopleAltIcon />}
          >
            Login
          </Button>
        </Link>
        <span
          style={{
            display: "flex",
            border: "1px solid white",
            marginRight: "10px",
          }}
        ></span>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/create-account">
            <Button
              sx={{
                textTransform: "none",
                background: "white",
                borderRadius: "16px",
                color: "black",
                "&:hover": {
                  background: "whitesmoke",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  color: "black",
                },
                "@media (max-width:800px)": {
                  padding: "2px 10px",
                },
              }}
              variant="contained"
            >
              Join Now
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {desktopViewSlogan}
      <AppBar position="static" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* This will be hidden on mobile and shown on desktop */}
          <Link to="/">
            <Typography
              variant="h5"
              component="div"
              sx={{
                display: { xs: "none", sm: "block" },
                color: "#0C356A",
                fontWeight: 600,
              }}
            >
              Docmanageplus
            </Typography>
          </Link>
          {/* This will center the logo on mobile and hide on desktop */}
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "none" },
              textAlign: "center",
              color: "#0C356A",
              fontWeight: 600,
            }}
          >
            <span>
              <img src={logo} alt="Docmanageplus" height={30} width="auto" />
            </span>
            <span>Docmanageplus</span>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              gap: "30px",
              "@media (max-width:800px)": {
                gap: "16px",
              },
            }}
          >
            {navItems.map((item) => (
              <Button
                key={item}
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "18px",
                  "@media (max-width:800px)": {
                    fontSize: "13px",
                  },
                }}
              >
                {item}
              </Button>
            ))}
          </Box>

          <Box sx={{ display: { xs: "none", sm: "block" }, ml: "auto" }}>
            {demoBtn}
          </Box>

          {/* This will only show the Demo button on the right on mobile */}
          <Box sx={{ display: { xs: "block", sm: "none" }, ml: "auto" }}>
            {demoBtn}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default HomeAppBar;
