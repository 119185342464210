import React from "react";
import { Box, Button, Grid } from "@mui/material";
import HomeAppBar from "../homescreen/HomeAppBar";

import ImageCard from "./ImageCard";
import BgImageSection from "./BgImageSection";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Footer from "./Footer";
import { whyDocmanageplusCardsData } from "./HomeUtils";

const HomePage = () => {
  return (
    <Box height="100%" width="100%" bgcolor="white" color="black">
      {/* navbar */}
      <HomeAppBar />
      {/* hero image section */}
      <BgImageSection />
      {/* headline section */}
      <Box
        sx={{
          height: "50px",
          background: "#023275",
          marginTop: "1%",
          padding: "0 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width:700px)": {
            height: "40px",
          },
          "@media (max-width:355px)": {
            height: "30px",
            marginTop: "2%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "white",
            "@media (max-width:1200px)": {
              fontSize: "18px",
            },
            "@media (max-width:700px)": {
              fontSize: "13px",
              fontWeight: "normal",
            },
            "@media (max-width:375px)": {
              fontSize: "11px",
              fontWeight: "normal",
            },
          }}
        >
          Fostering Trust and Excellence in Every Aspect of Patient Care.
        </Box>
      </Box>
      {/* why Docmanageplus section */}
      <Box
        sx={{
          bgcolor: "#0C356A",
          boxShadow:
            "0px 1px 1px 0px rgba(0, 0, 0, 0.17) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          padding: "1%",
          marginTop: "1%",
          "@media (max-width:355px)": {
            marginTop: "2%",
          },
        }}
      >
        <Box component="div">
          <Box
            sx={{
              color: "white",
              fontWeight: 600,
              fontSize: "40px",
              textAlign: "center",
              "@media (max-width:1200px)": {
                fontSize: "30px",
              },
              "@media (max-width:700px)": {
                fontSize: "25px",
                py: "5px",
              },
            }}
          >
            Why Docmanageplus?
          </Box>
          <Box
            sx={{
              color: "white",
              fontSize: "13px",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            At Docmanageplus, Trust and Quality Guide Every Step of Your Healthcare
            Journey.
          </Box>
        </Box>
        <Grid
          container //  makes this Grid component a container for other Grid items.
          spacing={{ xs: 2, md: 3 }} // sets the spacing between grid items, Here, the spacing is 2 on extra-small screens and 3 on medium and larger screens.
          columns={{ xs: 1, sm: 1, md: 4, lg: 12 }} // There are 1 columns on extra-small screens, 1 on small screens, and 12 on larger screens.
          py={2}
          justifyContent="center"
        >
          {whyDocmanageplusCardsData.map((card, index) => (
            /**
            Example :
            xs={2} means that on extra-small screens, this item will take up 2 of the 4 columns available (as defined in the container).
            sm={4} and md={4} mean that on small and medium screens, the item will take up 4 of the 8 and 12 columns available, respectively.
          */
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              lg={3}
              key={index}
              justifyContent="center"
            >
              <ImageCard
                title={card.title}
                description={card.content}
                imageUrl={card.img}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* what we do section */}
      <Box
        sx={{
          bgcolor: "#ccdfec",
          padding: "1.5rem",
          marginTop: "2rem",
        }}
      >
        <Box component="div">
          <Box
            sx={{
              color: "black",
              fontWeight: 600,
              fontSize: "40px",
              textAlign: "center",
              "&::first-letter": {
                color: "#0C356A",
              },
              "@media (max-width:1200px)": {
                fontSize: "35px",
              },
              "@media (max-width:700px)": {
                fontSize: "25px",
              },
            }}
          >
            What we <span style={{ color: "#0C356A" }}>Do</span>?
          </Box>
          <Box
            sx={{
              color: "#0B2447",
              fontSize: "13px",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            Redefining Healthcare: Intuitive Web Application for Streamlined
            Patient Management, Customized Care Plans, and Swift Report
            Generation.
          </Box>
        </Box>
        <Box
          sx={{
            px: 5,
            py: 2,
            "@media (max-width:1200px)": {
              px: 3,
            },
            "@media (max-width:700px)": {
              px: 1,
            },
          }}
        >
          <Box
            sx={{
              color: "black",
              fontWeight: 600,
              fontSize: "30px",
              "@media (max-width:1200px)": {
                fontSize: "25px",
              },
              "@media (max-width:700px)": {
                fontSize: "19px",
              },
            }}
          >
            <span style={{ color: "#0C356A" }}>Docmanageplus</span> : Transforming
            Patient Care with Advanced Data Solutions for Healthcare
            Professionals.
          </Box>
          <Box
            sx={{
              fontSize: "15px",
              lineHeight: "normal",
              letterSpacing: "0.42px",
              color: "black",
              py: 2,
              "@media (max-width:700px)": {
                fontSize: "13px",
              },
            }}
          >
            Docmanageplus's platform is meticulously crafted to encompass the critical
            functionalities healthcare professionals need. Secure and intuitive
            access points lead to a robust administration system designed to
            elevate patient management. With immediate access to detailed
            patient records and a user-friendly dashboard for generating
            reports, our system enhances medical practice efficiency. Beyond
            essential features, Docmanageplus offers advanced tools like medication
            scheduling to refine treatment protocols. We dedicate ourselves to
            equipping medical practitioners with an empowering suite of
            resources, aiming for the pinnacle of patient care through
            streamlined information access and adaptable scheduling options.
          </Box>
          <Box textAlign={"center"}>
            <Button
              sx={{
                textTransform: "none",
                color: "#0C356A",
                border: "2px solid #1472ED",
                borderRadius: "999px",
                padding: "5px 30px",
                fontWeight: "600",
                "@media (max-width:700px)": {
                  padding: "2px 10px",
                },
              }}
              startIcon={<ArrowBackIosIcon style={{ color: "#0C356A" }} />}
            >
              Demo
            </Button>
          </Box>
        </Box>
      </Box>

      {/* contact us section */}
      <Box p={2}></Box>

      {/* footer section */}
      <Footer />
    </Box>
  );
};

export default HomePage;
