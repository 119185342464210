import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Card,
  Tooltip,
  Container,
} from "@mui/material";

import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import AuthContext from "../../context/AuthContext";
import { PASSWORD_REGEX } from "../../context/contextUtils";
import {
  darkThemeStyles,
  saveButtonStyle,
} from "../../styledComponents/CustomStyleComponents";
import { Helmet } from "react-helmet";

const passwordField = (
  label,
  field,
  value,
  onChange,
  showPassword,
  handlePasswordVisibility,
  fieldError
) => {
  let errorMessage = "";
  if (field === "current" && fieldError.oldPassword) {
    errorMessage = "Current password is required";
  } else if (field === "new" && fieldError.newPassword) {
    errorMessage = "New password is required";
  } else if (field === "new" && fieldError.newPasswordRegex) {
    errorMessage = `Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character`;
  } else if (field === "confirm" && fieldError.confirmPassword) {
    errorMessage = "Confirmation password is required";
  } else if (field === "confirm" && fieldError.passwordMismatch) {
    errorMessage = "Passwords do not match";
  }
  return (
    <>
      <TextField
        fullWidth
        error={!!errorMessage}
        helperText={errorMessage}
        label={label}
        value={value}
        onChange={onChange}
        type={showPassword[field] ? "text" : "password"}
        variant="outlined"
        margin="normal"
        InputLabelProps={{ style: { color: "#BBBBBB" } }}
        InputProps={{
          style: { color: "#FFFFFF" },
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title={showPassword[field] ? "Hide Password" : "Show Password"}
              >
                <IconButton
                  onClick={() => handlePasswordVisibility(field)}
                  color="primary"
                  size="small"
                >
                  {showPassword[field] ? (
                    <VisibilityOff
                      fontSize="small"
                      style={{ color: "#BBBBBB" }}
                    />
                  ) : (
                    <Visibility fontSize="small" style={{ color: "#BBBBBB" }} />
                  )}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#3A3A3A", // Lighter grey for idle border
            },
            "&:hover fieldset": {
              borderColor: "#5A5A5A", // Slightly brighter grey for hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#BB86FC", // Soothing Purple for focus
            },
          },
          "& .Mui-error": {
            color: "#FF5555", // Soft Red for error messages
          },
          "& .MuiFormHelperText-root": {
            color: "#FF5555", // Soft Red for error helper text
          },
        }}
      />
    </>
  );
};

const ChangePassword = () => {
  const { resetPasswordAPI } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [oldPasswordValue, setOldPasswordValue] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    oldPassword: false,
    newPassword: false,
    newPasswordRegex: false,
    confirmPassword: false,
    passwordMismatch: false,
  });

  const handlePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const resetPasswordBtnSubmitHandler = async (e) => {
    e.preventDefault();
    let errors = {
      oldPassword: !oldPasswordValue,
      newPassword: !newPasswordValue,
      newPasswordRegex: !PASSWORD_REGEX.test(newPasswordValue),
      confirmPassword: !confirmPasswordValue,
      passwordMismatch: newPasswordValue !== confirmPasswordValue,
    };
    setFieldErrors(errors);

    if (
      errors.oldPassword ||
      errors.newPassword ||
      errors.newPasswordRegex ||
      errors.confirmPassword ||
      errors.passwordMismatch
    ) {
      return;
    }

    // handle the actual password reset here
    let isResetSuccessfully = await resetPasswordAPI(
      oldPasswordValue,
      newPasswordValue
    );
    if (isResetSuccessfully) {
      setOldPasswordValue("");
      setNewPasswordValue("");
      setConfirmPasswordValue("");
    }
  };

  return (
    <>
      <Helmet>
        <title>Docmanageplus | Change Password</title>
      </Helmet>
      <Box
        width="100vw"
        minHeight="calc(100vh - 50px)"
        bgcolor="#121212"
        color="#e1e1e1"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          py={5}
        >
          <Container>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Lock fontSize="large" style={{ color: "#FFFFFF" }} />
                <Typography
                  variant="h5"
                  marginLeft="10px"
                  style={{ color: "#FFFFFF" }}
                >
                  Change Password
                </Typography>
              </Box>
            </Box>

            <Box my={4}>
              <Typography variant="body1" style={{ color: "#BBBBBB" }}>
                To change your password, enter the fields below, make sure the
                new password doesn't match the current one and has a mix of
                alphanumeric and special characters.
              </Typography>
            </Box>

            <Card
              elevation={3}
              style={{
                backgroundColor: darkThemeStyles.surface,
                padding: "20px",
                width: "100%",
                borderRadius: "16px",
                "&:hover": {
                  boxShadow: "rgba(255, 255, 255, 0.1) 0px 0px 20px", // subtle white glow on hover
                },
              }}
            >
              <form onSubmit={resetPasswordBtnSubmitHandler}>
                <Box mb={2}>
                  {passwordField(
                    "Current Password",
                    "current",
                    oldPasswordValue,
                    (e) => setOldPasswordValue(e.target.value),
                    showPassword,
                    handlePasswordVisibility,
                    fieldErrors
                  )}
                </Box>

                <Box mb={2}>
                  {passwordField(
                    "New Password",
                    "new",
                    newPasswordValue,
                    (e) => setNewPasswordValue(e.target.value),
                    showPassword,
                    handlePasswordVisibility,
                    fieldErrors
                  )}
                </Box>

                <Box mb={4}>
                  {passwordField(
                    "Confirm New Password",
                    "confirm",
                    confirmPasswordValue,
                    (e) => setConfirmPasswordValue(e.target.value),
                    showPassword,
                    handlePasswordVisibility,
                    fieldErrors
                  )}
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={saveButtonStyle}
                    type="submit"
                  >
                    Change Password
                  </Button>
                </Box>
              </form>
            </Card>
          </Container>
        </Box>
      </Box>
    </>
  );
};
export default ChangePassword;
