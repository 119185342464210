import { Box, Button, Card, CircularProgress, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo_without_bg.png";
import { grey } from "@mui/material/colors";
import AuthContext from "../../../context/AuthContext";
import { debounce } from "lodash";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const {
    resetPasswordLinkSendingAPI,
    resetPasswordLinkMessage,
    setResetPasswordLinkMessage,
  } = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSubmitRef = useRef(null);

  useEffect(() => {
    // Create a debounced function and store it in a ref
    debouncedSubmitRef.current = debounce(async (username) => {
      setLoading(true);
      await resetPasswordLinkSendingAPI(username);
      setLoading(false);
    }, 1000);

    // Cleanup function to cancel any pending debounced calls
    return () => {
      if (debouncedSubmitRef.current) {
        debouncedSubmitRef.current.cancel();
      }
    };
  }, [resetPasswordLinkSendingAPI]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (debouncedSubmitRef.current) {
      debouncedSubmitRef.current(username);
    }
  };

  const sendResetLinkForm = (
    <>
      <Box
        component="h1"
        sx={{
          fontSize: "24px",
          lineHeight: 1.5,
          textAlign: "center",
          fontWeight: 600,
          mb: 2,
          "@media (max-width:700px)": {
            fontSize: "20px",
            mb: 1,
          },
          "@media (max-width:360px)": {
            fontSize: "19px",
          },
        }}
      >
        Trouble logging in?
      </Box>
      <Box
        sx={{
          color: grey[600],
          textAlign: "center",
          mb: 3,
          "@media (max-width:700px)": {
            fontSize: "15px",
            mb: "15px",
          },
        }}
      >
        Enter your email address and we'll send you a link to get back into your
        account.
      </Box>
      <Box component="form" onSubmit={submitHandler}>
        <Box mb="20px">
          <TextField
            autoFocus
            id="usernameForPasswordReset"
            variant="outlined"
            name="username"
            fullWidth
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            placeholder="Email Address"
            error={
              resetPasswordLinkMessage
                ? "error" in resetPasswordLinkMessage
                : false
            }
            helperText={resetPasswordLinkMessage?.error}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#0066cc",
            "&:hover": {
              backgroundColor: "#004d99",
            },
            borderRadius: "50px",
            paddingY: "10px",
          }}
          disabled={loading || !username}
        >
          {loading ? <CircularProgress size={24} /> : "Send Reset Link"}
        </Button>

        <Box
          sx={{
            color: grey[600],
            textAlign: "center",
            pt: 2,
            pb: 1,
            "@media (max-width:700px)": {
              fontSize: "15px",
            },
          }}
        >
          OR
        </Box>
        <Box
          sx={{
            textAlign: "center",
            "&:hover": { textDecoration: "underline", cursor: "pointer" },
            "@media (max-width:700px)": {
              fontSize: "15px",
            },
          }}
        >
          <Link to="/create-account" style={{ color: "#00376B" }}>
            Create New Account
          </Link>
        </Box>
      </Box>
    </>
  );

  const successPageAfterLinkSent = (
    <Box p="1rem 0" display="flex" flexDirection="column" alignItems="center">
      <Box fontWeight="600" mb="20px" fontSize="18px">
        Email Sent
      </Box>
      <Box
        mb="20px"
        sx={{
          "@media (max-width:700px)": {
            fontSize: "16px",
          },
        }}
      >
        {resetPasswordLinkMessage?.message}
      </Box>
      <Button
        onClick={() => {
          setResetPasswordLinkMessage(null);
          setUsername("");
        }}
      >
        OK
      </Button>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>Docmanageplus | Forgot Password</title>
      </Helmet>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "#ccdfec",
        }}
      >
        <Box
          py="15px"
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <Box component="span">
              <img src={logo} alt="" width="70px" height="auto" />
            </Box>
            <Box
              component="span"
              sx={{
                fontSize: "30px",
                fontWeight: 600,
                color: "#0C356A",
                "@media (max-width:360px)": {
                  fontSize: "25px",
                },
              }}
            >
              Docmanageplus
            </Box>
          </Link>
        </Box>
        <Card
          sx={{
            backgroundColor: "white",
            padding: "20px 40px",
            width: "90%",
            maxWidth: "500px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            "@media (max-width:700px)": {
              padding: "15px 20px",
            },
          }}
        >
          {resetPasswordLinkMessage !== null
            ? "error" in resetPasswordLinkMessage
              ? sendResetLinkForm
              : successPageAfterLinkSent
            : sendResetLinkForm}
        </Card>
        <Box
          color="rgb(70 97 133)"
          paddingTop="20px"
          sx={{
            "@media (max-width:700px)": {
              fontSize: "15px",
            },
          }}
        >
          Remember Password? &nbsp;
          <Box
            component="span"
            color="black"
            sx={{
              "&:hover": { textDecoration: "underline", cursor: "pointer" },
              "@media (max-width:700px)": {
                fontSize: "15px",
              },
            }}
          >
            <Link to="/login">Login</Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
