import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import logo from "../../../assets/images/logo_without_bg.png";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const { loginUserHandler, authTokens } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false); // State for loading indicator

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const validate = () => {
    const newError = {};

    if (!username.trim()) newError.username = "Username is required!";
    if (!password.trim()) newError.password = "Password is required!";

    setError(newError);
    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setLoading(true);
      await loginUserHandler(username, password); // Assuming it returns a promise
      setLoading(false);
      setPassword("");
    }
  };

  const loginPage = (
    <>
      <Helmet>
        <title>Docmanageplus | Login</title>
      </Helmet>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "#ccdfec",
        }}
      >
        <Box
          pb="10px"
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <Box component="span">
              <img src={logo} alt="" width="70px" height="auto" />
            </Box>
            <Box
              component="span"
              sx={{
                fontSize: { xs: "25px", sm: "28px", md: "30px" },
                fontWeight: 600,
                color: "#0C356A",
              }}
            >
              Docmanageplus
            </Box>
          </Link>
        </Box>

        {/* Card Section */}
        <Card
          sx={{
            backgroundColor: "white",
            padding: "0px 40px",
            width: "80%",
            maxWidth: "500px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            "@media (max-width:700px)": {
              padding: "0px 15px",
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingY="10px"
            sx={{
              "@media (max-width:700px)": {
                pb: "10px",
              },
            }}
          >
            <Box
              width="40px"
              height="40px"
              borderRadius="50%"
              sx={{ bgcolor: "rgb(246, 248, 251)" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoginOutlinedIcon
                sx={{
                  color: "rgb(120, 139, 165)",
                }}
              />
            </Box>
          </Box>

          <Box
            component="h1"
            sx={{
              fontSize: "24px",
              lineHeight: 1.5,
              textAlign: "center",
              fontWeight: 600,
              mb: 2,
              "@media (max-width:700px)": {
                fontSize: "20px",
                mb: 1,
              },
              "@media (max-width:360px)": {
                fontSize: "19px",
              },
            }}
          >
            Log in with your work email
          </Box>
          <Box
            sx={{
              color: grey[600],
              textAlign: "center",
              mb: 1,
              "@media (max-width:700px)": {
                fontSize: "15px",
                mb: 0.5,
              },
            }}
          >
            Use your work email to log in to your workspace.
          </Box>

          <Box component="form" py="1rem" onSubmit={handleSubmit}>
            <Box mb="20px">
              <TextField
                autoFocus
                label="Username"
                id="username"
                variant="outlined"
                name="username"
                fullWidth
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, username: "" }));
                }}
                error={Boolean(error.username)}
                helperText={error.username}
              />
            </Box>

            <Box mb="20px">
              <TextField
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, password: "" }));
                }}
                error={Boolean(error.password)}
                helperText={error.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handlePasswordVisibility}
                        aria-label="Toggle password visibility"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#0066cc",
                "&:hover": {
                  backgroundColor: "#004d99",
                },
                borderRadius: "50px",
                paddingY: "10px",
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Login"}
            </Button>

            <Box
              sx={{
                color: grey[600],
                textAlign: "center",
                pt: 1,
                pb: 1,
                "@media (max-width:700px)": {
                  fontSize: "15px",
                },
              }}
            >
              OR
            </Box>
            <Box
              sx={{
                textAlign: "center",
                "&:hover": { textDecoration: "underline", cursor: "pointer" },
                "@media (max-width:700px)": {
                  fontSize: "15px",
                },
              }}
            >
              <Link to="/forgot-password" style={{ color: "#00376B" }}>
                Forgot Password?
              </Link>
            </Box>
          </Box>
        </Card>
        <Box
          color="rgb(70 97 133)"
          paddingTop="20px"
          sx={{
            "@media (max-width:700px)": {
              fontSize: "15px",
            },
            "@media (max-width:275px)": {
              fontSize: "12px",
            },
          }}
        >
          Don't have an account yet? &nbsp;
          <Box
            component="span"
            color="black"
            sx={{
              "&:hover": { textDecoration: "underline", cursor: "pointer" },
              "@media (max-width:700px)": {
                fontSize: "15px",
              },
              "@media (max-width:275px)": {
                fontSize: "12px",
              },
            }}
          >
            <Link to="/create-account">Create Account</Link>
          </Box>
        </Box>
      </Box>
    </>
  );
  return <>{authTokens ? <Navigate to="/dashboard" /> : loginPage}</>;
};

export default LoginPage;
